<template>
    <modal :show.sync="tmp_visible" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <div slot="header" class="modal-title px-3">
            <h4>创建年度计划表</h4>
        </div>
        <card type="secondary" shadow header-classes="bg-white pb-2" body-classes="p-lg-3 pt-lg-4" class="border-0">
            <div class="container">
                <!-- Table -->
                <div class="row justify-content-center">
                    <div class="col">
                        <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
                            <div class="row">
                                <div class="col">
                                <label class="form-label required">年份</label>
                                <el-form-item prop="year" :rules="[{required:true}]">
                                    <el-input v-model="model.year" type="number" placeholder="填写年份"></el-input>
                                </el-form-item>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">创建</button>
                                <button type="button" class="btn btn-secondary mt-4 ls-3" @click="cancel">取消</button>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </card>
    </modal>
</template>

<script>
//import { Form, FormItem } from 'element-ui';

export default {
    // components: {
    //     [Form.name]: Form,
    //     [FormItem.name]: FormItem
    // },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        newYear: {
            type: String,
            default: null
        }
    },
    data() {
      return {
        tmp_visible: this.visible,
        tmp_year: this.newYear,
        loading: false,
        model: {
            year: new Date().getFullYear()
        }
      }
    },
    watch: {
      tmp_visible(v) {
          this.$emit("update:visible", v);
      },
      visible(v) {
          this.tmp_visible = v
      },
      tmp_year(v) {
          this.$emit("update:newYear", v);
      },
    },
    methods: {
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            this.axios({
                method: 'post',
                url: 'plans',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.$notifyOK('操作成功', `已创建${this.model.year}年份的计划表`);
                    this.tmp_visible = false;
                    this.tmp_year = this.model.year;
                }
                else {
                    this.$notifyErr('保存失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('保存失败', err.response.data.error.message);
                    else this.$notifyErr('保存失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_visible = false;
        }
    }
};
</script>
<style scoped>
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
</style>